export const introSectionContent = {
  title: {
    pl: 'Newsy - Blog Transportowy',
    en: 'News',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Przygotowaliśmy blog transportowy. Aktualne informacje, porady i ciekawostki. Eksploruj świat TSL!',
    en: 'CARGONITE team runs transport blog. Fresh news, tips and fun facts. Explore the TSL world!',
  },
}
