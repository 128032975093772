import React from 'react'
import Layout from '../components/Layout'
import News from '../page/News/News'

const NewsPage = () => (
  <Layout>
    <News />
  </Layout>
)

export default NewsPage
