export const seo = {
  title: {
    pl: 'CARGONITE News | Artykuły o Transporcie i Logistyce | CARGONITE',
    en: 'CARGONITE News | Transport and Logistics Articles',
  },
  description: {
    pl: 'Zespół CARGONITE przygotował blog transportowy. Aktualne informacje, porady i ciekawostki. Eksploruj świat TSL!',
    en: 'CARGONITE team runs transport blog. Fresh news, tips and fun facts. Explore the TSL world!',
  },
}
