import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { s } from '../../../style'
import Thumbnail from '../../../components/Thumbnail'
import { getImage } from 'gatsby-plugin-image'
import { gInput, gSelect } from '../../../style/config/globals'

const NewsAll = () => {
  const { lang } = useLangContext()
  const [searchPhrase, setSearchPhrase] = useState('')
  const [searchCategory, setSearchCategory] = useState('')

  const query = useStaticQuery(graphql`
    query {
      news: allContentfulNews(sort: { fields: publishDate, order: DESC }) {
        edges {
          node {
            title
            desc
            slug
            category {
              slug
            }
            image {
              title
              gatsbyImageData(
                width: 600
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
            }
            lang {
              slug
            }
          }
        }
      }
      categories: allContentfulNewsCategory(sort: { fields: slug }) {
        edges {
          node {
            slug
            lang {
              slug
            }
          }
        }
      }
    }
  `)

  const news = query.news.edges
    .filter(({ node }) => node.lang.slug === lang)
    .filter(({ node }) =>
      searchPhrase
        ? node.title.toLowerCase().includes(searchPhrase.toLowerCase()) ||
          node.desc.toLowerCase().includes(searchPhrase.toLowerCase())
        : true
    )
    .filter(({ node }) =>
      searchCategory ? node.category.slug === searchCategory : true
    )
    .map(({ node }) => node)

  const categories = query.categories.edges
    .filter(({ node }) => node.lang.slug === lang)
    .map(({ node }) => node.slug)

  const phrasePlaceholder = {
    pl: 'Wyszukaj po frazie',
    en: 'Search by phrase',
  }

  const categoryPlaceholder = {
    pl: 'Wszystkie kategorie',
    en: 'All categories',
  }

  return (
    <section css={sOuter}>
      <div css={sFilters}>
        <input
          css={[gInput, sInput]}
          type='text'
          placeholder={phrasePlaceholder[lang]}
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value)}
        />
        <select
          css={[gInput, gSelect]}
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}>
          <option value=''>{categoryPlaceholder[lang]}</option>
          {categories.map((category, id) => (
            <option key={id} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div css={sInner}>
        {news.map((post, id) => (
          <Thumbnail
            key={id}
            title={post.title}
            subtitle={post.category.slug}
            desc={post.desc}
            image={getImage(post.image)}
            to={`/news/${post.slug}`}
            isBlog
          />
        ))}
      </div>
    </section>
  )
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '50px auto 0',
  display: 'flex',
  flexDirection: 'column',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px 100px',
  gap: 50,
  [s.xs]: {
    margin: '0 25px 100px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 200px',
  },
}

const sFilters = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 50px 50px',
  [s.xs]: {
    margin: '0 25px 50px',
    flexDirection: 'column',
  },
}

const sInput = {
  width: 'calc(50% - 50px)',
  margin: '0 50px 0 0',
  [s.xs]: {
    width: '100%',
    margin: '0 0 25px 0',
  },
}

export default NewsAll
